.image1 {
  height: 300px;
  width: 400px;
  animation-name: stretch;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
}

@keyframes stretch {
  0% {
    transform: scale(0.5);
  }
  50% {
  }
  100% {
    transform: scale(1.3);
  }
}

@media only screen and (max-width: 700px) {
  .image1 {
    height: 200px;
    width: 200px;
  }
}
