.books-select-box {
  padding: 2px 10px !important;
  height: 42px;
  background: #fff;
  border-radius: 4px;
  margin-top: 6px;
}

.books-select-box svg {
  vertical-align: baseline;
}

.books-select-box svg path {
  color: #e0c9b4 !important;
}

.books-select-box {
  border: 1px solid #e0c9b4 !important;
}

.books-select-box .arrow {
  width: 12px;
  height: 12px;
}

.books-select-box .react-dropdown-select-content > span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}