body {
  background-attachment: fixed;
  background-size: contain;
  font-family: 'Patrick Hand' !important;
}
#root {
  padding: 0px !important;
  margin: 0 !important;
}
#root > .MuiContainer-root {
  padding: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

#root > .MuiContainer-maxWidthLg {
  max-width: 14208px !important;
}
/* .makeStyles-appBarSpacer-2 {
  min-height: 44px !important;
} */

html,
body,
#root,
.MuiContainer-root {
  height: 100%;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.text-center {
  text-align: center !important;
}
.error-color {
  color: #d63232 !important;
}
.text-bold {
  font-weight: bold !important;
}
.form-error {
  height: 17px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #d63232 !important;
  line-height: 17px;
  margin-top: 2px;
}

.msgTip .MuiPaper-root {
  top: 80px !important;
  left: 50% !important;
  margin-left: -300px;
  width: 600px;
  /* height: 56px; */
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  border: 1px solid #ffffff;
  text-align: center;
  line-height: 56px;
  font-size: 14px;
  font-family: Helvetica;
  color: #666666;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
}

.two-line-ellipsis {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.react-dropdown-select-dropdown {
  padding: 6px 0px !important;
}

.search-empty-box {
  margin: 150px auto;
  text-align: center;
}

.search-empty-box img {
  width: 74px;
  height: 74px;
}

.search-empty-box p {
  font-size: 16px;
  font-family: Helvetica;
  color: #bababa;
  margin-top: 20px;
}
