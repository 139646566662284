.upload-book-page .picker .MuiInputBase-root::before {
  content: none;
}

.upload-book-page .picker .MuiInputBase-root {
  height: 100%;
}

.upload-book-page .picker .MuiInputBase-input {
  padding-left: 13px;
}

.upload-book-page .picker .MuiIconButton-label svg path {
  color: #e0c9b4;
}

.books-select-box {
  height: 42px;
}

.upload-book-page .re-upload {
  width: 357px;
  height: 42px;
  background: rgba(229, 164, 119, 0.9);
  border: 1px solid #e5a477;
  font-size: 15px;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  color: #261c45;
  line-height: 42px;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: -2px;
  border-radius: 0px 0px 4px 4px;
  cursor: pointer;
  z-index: 2;
}

.upload-book-page .re-upload-input {
  z-index: 4;
}

.state-1 {
  margin-top: 15px !important;
  height: 25px;
  font-family: Helvetica;
  font-weight: normal;
  line-height: 25px;
  margin-bottom: 0px;
  color: #dc6919;
}
