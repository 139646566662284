.modal-confirm-wrap .modal-bg {
  position: fixed;
  inset: 0;
  background: #333333;
  opacity: 0.75;
}
.modal-confirm-wrap .modal-body {
  width: 800px;
  height: 105px;
  background: #ffffff;
  border-radius: 20px;
  position: fixed;
  top: 200px;
  left: 50%;
  margin-left: -400px;
  z-index: 9;
}
.modal-confirm-wrap .content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 14px;
  justify-content: space-between;
}

.modal-confirm-wrap .notice-icon {
  width: 45px;
  height: 45px;
  flex: 0;
  margin-right: 10px;
}

.modal-confirm-wrap .msg {
  font-size: 22px;
  font-family: Helvetica;
  color: #1b2150;
  margin-bottom: 0px;
  text-align: left;
  flex: 1;
}

.modal-confirm-wrap .btn-confirm {
  height: 45px;
  background: #261c45;
  font-size: 17px;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  color: #f9f3ea;
  line-height: 45px;
  flex: 0;
  padding: 0px 40px;
  white-space: nowrap;
  cursor: pointer;
}
.modal-confirm-wrap .close-btn {
  border-radius: 20px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #ffffff;
  padding: 3px;
  position: absolute;
  right: -20px;
  top: -20px;
  cursor: pointer;
}
.modal-confirm-wrap .close-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
