.my-books-search {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #ffffff;

  color: #261c45;
}

.my-books-search .form-item > .label {
  font-size: 14px;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  color: #261c45;
  line-height: 17px;
}

.my-books-search .react-dropdown-select-content > span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}

.my-books-search .search-input-box {
  display: flex;
  align-items: center;
  width: 450px;
  height: 40px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 5px;
  border: 1px solid #e0c9b4;
}

.my-books-search .books-select-box {
  margin-top: 0px !important;
  height: 40px !important;
  border-radius: 4px !important;
}

.my-books-search .search-input-box input {
  height: 100%;
  padding: 10px;
  border-radius: 5px;
  color: #261c45;
  width: 100%;
  border: none;
  outline: none;
  flex: 1;
  font-weight: normal;
  font-size: 14px;
  font-family: Helvetica;
  color: #333333;
}
.my-books-search .search-input-box span {
  flex: 0 0 auto;
  height: 100%;
  width: 60px;
  border-radius: 0px;
  background: #e0c9b4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.my-books-search .search-input-box span img {
  width: 24px;
  height: 24px;
}

.page-wrap {
  display: flex;
}

.btn-operate {
  font-size: 16px;
  font-family: Helvetica;
  color: #2c7bdb;
  line-height: 25px;
  display: flex;
  font-weight: normal;
  flex-wrap: wrap;
}


.btn-operate .su {
  margin: 0px 5px;
  color: #999;
}

.btn-operate .del {
  color: #d63232;
  cursor: pointer;
}

.btn-operate .edit {
  cursor: pointer;
}

.btn-operate .upload {
  cursor: pointer;
}

.clear-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 115px;
  border-radius: 50%;
  cursor: pointer;
}

.status-1 {
  height: 25px;
  font-family: Helvetica;
  font-weight: normal;
  line-height: 25px;
  margin-bottom: 0px;
  color: #dc6919;
}
