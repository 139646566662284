.my-books-search {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #ffffff;

  color: #261c45;
}

.my-books-search .export-btn {
  height: 40px;
  background: #e0c9b4;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  color: #333;
  display: inline-block;
  margin-left: 15px;
  padding: 0px 24px;
  font-family: Helvetica;
  cursor: pointer;
}
.my-books-search .form-item > .label {
  font-size: 14px;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  color: #261c45;
  line-height: 17px;
}

.my-books-search .react-dropdown-select-content > span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}

.my-books-search .search-input-box {
  display: flex;
  align-items: center;
  width: 450px;
  height: 40px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 5px;
  border: 1px solid #e0c9b4;
}

.my-books-search .books-select-box {
  margin-top: 0px !important;
  height: 40px !important;
  border-radius: 4px !important;
}

.my-books-search .search-input-box input {
  height: 100%;
  padding: 10px;
  border-radius: 5px;
  color: #261c45;
  width: 100%;
  border: none;
  outline: none;
  flex: 1;
  font-weight: normal;
  font-size: 14px;
  font-family: Helvetica;
  color: #333333;
}
.my-books-search .search-input-box span {
  flex: 0 0 auto;
  height: 100%;
  width: 60px;
  border-radius: 0px;
  background: #e0c9b4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.my-books-search .search-input-box span img {
  width: 24px;
  height: 24px;
}

.page-wrap {
  display: flex;
}

.btn-operate {
  font-size: 16px;
  font-family: Helvetica;
  color: #2c7bdb;
  line-height: 19px;
  display: flex;
  font-weight: normal;
}

.btn-operate .su {
  margin: 0px 5px;
  color: #999;
}

.btn-operate .del {
  color: #d63232;
  cursor: pointer;
}

.btn-operate .edit {
  cursor: pointer;
}

.btn-operate .upload {
  cursor: pointer;
}

.order-info {
  display: flex;
  height: 48px;
  background: #fff;
  border-radius: 10px;
  line-height: 48px;
  padding: 0px 10px;
  margin: 20px 0px 10px;
}

.order-info .order-item {
  font-size: 15px;
  font-family: Helvetica;
  font-weight: normal;
  color: #261c45;
  margin-right: 15px;
}

.clear-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 115px;
  border-radius: 50%;
}

.my-orders .card-list-title {
  height: 48px;
  background: #f9f6eb;
  border-radius: 10px;
  border: 1px solid #e0c9b4;
  line-height: 48px;
  padding: 0px 10px;
  margin: 20px 0px;
}

.my-orders .card-list-body {
  margin-top: 10px;
  height: 98px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #ffffff;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: Helvetica;
}

.my-orders .book-info {
  display: flex;
  align-items: center;
}

.my-orders .book-info .cover {
  width: 70px;
  height: 76px;
  margin-right: 10px;
}

.my-orders .empty-box {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 250px;
}

.btn-operate .su {
  margin: 0px 5px;
  color: #999;
}

.btn-operate .del {
  color: #d63232;
  cursor: pointer;
}

.btn-operate .edit {
  cursor: pointer;
}

.btn-operate .upload {
  cursor: pointer;
}