.card-wrap {
  font-family: Helvetica-Bold, Helvetica;
  width: 100%;
  min-height: 100%;
  background: #f4f4f4;
  border: 1px solid #f9f6eb;
  color: #261c45;
  padding: 102px 30px 20px;
}

.card-wrap .top-box {
  height: 60px;
  background: #ffffff;
  border: 1px solid #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
}

.card-wrap .s1 {
  font-size: 22px;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  color: #261c45;
  flex: 1;
}

.card-wrap .no-check-text {
  font-size: 16px;
  font-family: Helvetica;
  color: #999999;
  flex: 0;
  margin-left: 20px;
  white-space: nowrap;
}

.card-wrap .go-checkout {
  padding: 0px 20px;
  height: 40px;
  background: rgba(224, 201, 180, 0.95);
  border-radius: 5px;
  opacity: 0.5;
  border: 1px solid #e0c9b4;
  font-size: 16px;
  font-family: Helvetica;
  color: #261c45;
  line-height: 40px;
  text-align: center;
  flex: 0;
  margin-left: 10px;
  display: block;
  white-space: nowrap;
}
.card-wrap .go-checkout.active {
  opacity: 1;
  cursor: pointer;
}

.card-wrap .card-list-title {
  height: 48px;
  background: #f9f6eb;
  border-radius: 10px;
  border: 1px solid #e0c9b4;
  line-height: 48px;
  padding: 0px 10px;
  margin: 20px 0px;
}

.card-wrap .card-list-body {
  margin-top: 10px;
  height: 98px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #ffffff;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: Helvetica;
}

.card-wrap .book-info {
  display: flex;
  align-items: center;
}

.card-wrap .book-info .cover {
  width: 70px;
  height: 76px;
  margin-right: 10px;
}

.card-wrap .empty-box {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 250px;
}

.card-order-wrap {
  background: #f9f6eb;
}

.card-order-wrap .content {
  width: 668px;
  margin: 40px auto 0px;
}

.card-order-wrap .card-list-body {
  margin-top: 5px;
  min-height: 78px;
  border-radius: 8px;
}

.card-order-wrap .book-info .cover {
  width: 56px;
  height: 56px;
}

.card-order-wrap .total-info {
  width: 673px;
  height: 163px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #ffffff;
  text-align: right;
  margin-top: 60px;
  margin-bottom: 60px;
}
.card-order-wrap .price {
  font-size: 21px;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  color: #261c45;
  line-height: 28px;
  text-align: right;
  padding: 26px 30px 0px;
}
.card-order-wrap .price .red-text {
  color: #d63232;
}

.info-line {
  display: flex;
  flex-direction: row-reverse;
}

.card-order-wrap .time {
  height: 28px;
  font-size: 14px;
  font-family: Helvetica;
  color: #261c45;
  line-height: 28px;
  padding: 0px 30px 0px;
  margin-top: 6px;
}

.card-order-wrap .order-btn {
  margin-top: 25px;
  height: 49px;
  background: #261c45;
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #261c45;
  font-size: 21px;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  color: #f9f6eb;
  line-height: 49px;
  text-align: center;
  cursor: pointer;
}

.order-result .card-list-title {
  background: transparent;
}

.order-result .order-info {
  display: flex;
  height: 48px;
  background: #fff;
  border-radius: 10px;
  line-height: 48px;
  padding: 0px 10px;
  margin: 20px 0px;
}

.order-result .order-info .order-item {
  font-size: 15px;
  font-family: Helvetica;
  font-weight: normal;
  color: #261c45;
  margin-right: 15px;
}

.order-result .back-shop-btn {
  width: 420px;
  height: 56px;
  background: #261c45;
  border-radius: 28px;
  border: 1px solid #261c45;
  text-align: center;
  line-height: 56px;
  font-size: 25px;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  color: #f9f6eb;
  margin: 60px auto;
}

.order-result .card-box {
  text-align: left;
}
