.books-select-box {
  padding: 2px 10px !important;
}

.books-select-box svg {
  vertical-align: baseline;
}

.books-select-box svg path {
  color: #e0c9b4 !important;
}

.books-select-box {
  border: 1px solid #e0c9b4 !important;
}

.books-select-box .arrow {
  width: 12px;
  height: 12px;
}

.books-search {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #ffffff;

  color: #261c45;
}

.books-search .form-item > .label {
  font-size: 14px;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  color: #261c45;
  line-height: 17px;
}

.books-search .react-dropdown-select-content > span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}

.books-search .search-input-box {
  display: flex;
  align-items: center;
  width: 600px;
  height: 40px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 5px;
  border: 1px solid #e0c9b4;
}

.books-search .search-input-box input {
  height: 100%;
  padding: 10px;
  border-radius: 5px;
  color: #261c45;
  width: 100%;
  border: none;
  outline: none;
  flex: 1;
  font-weight: normal;
  font-size: 14px;
  font-family: Helvetica;
  color: #333333;
}
.books-search .search-input-box span {
  flex: 0 0 auto;
  height: 100%;
  width: 60px;
  border-radius: 0px;
  background: #e0c9b4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.books-search .search-input-box span img {
  width: 24px;
  height: 24px;
}
.books-wrap {
  margin-top: 20px;
}
.books-wrap .books-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5%;
  margin-right: -0.5%;
}
.books-wrap .books-list .item {
  width: 19%;
  height: 410px;
  background: #ffffff;
  border: 1px solid #ffffff;
  margin-bottom: 20px;
  padding: 20px;
  margin-left: 0.5%;
  margin-right: 0.5%;
}
.books-wrap .books-list .item:hover {
  border: 1px solid #e0c9b4;
}
.books-wrap .books-list .item img {
  width: 100%;
  height: 220px;
  object-fit: contain;
  display: block;
  background: #f1f1f1;
}

.books-wrap-detail {
  width: 1000px;
  height: 480px;
  margin-left: 210px;
  padding: 40px;
}

.books-wrap-detail .item {
  display: flex;
}
.books-wrap-detail .item p {
  font-family: Helvetica;
  margin-bottom: 5px;
}

.books-wrap-detail .item .detail-img {
  width: 400px;
  height: 400px;
}

.books-wrap-detail .item .detail-info {
  margin-left: 40px;
  flex: 1 1 auto;
}

.books-wrap-detail .item .add-card-btn {
  width: 240px;
  height: 70px;
  background: #261c45;
  border: 1px solid #261c45;
  font-size: 24px;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  color: #f9f3ea;
  line-height: 70px;
  text-align: center;
  margin-top: 30px;
  cursor: pointer;
}
.books-wrap .item .p1 {
  height: 28px;
  font-size: 24px;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  color: #261c45;
  line-height: 28px;
  margin-top: 18px;
  margin-bottom: 0px;
}

.books-wrap .item .p2 {
  font-size: 16px;
  font-family: Helvetica;
  color: #261c45;
  line-height: 18px;
  margin-top: 14px;
  margin-bottom: 0px;
}
.books-wrap .item .p3 {
  margin-top: 12px;
  font-size: 13px;
  font-family: Helvetica;
  color: #999999;
  line-height: 16px;
  margin-bottom: 0px;
}

.page-wrap {
  display: flex;
}

